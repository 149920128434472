import { PathEnum } from 'core/routes/path.enum';

export const SIDEBAR_ITEMS: SidebarItem[] = [
  { label: 'Attività', route: PathEnum.ACTIVITIES },
  { label: 'Libreria', route: PathEnum.LIBRARY },
  { label: 'Libreria Immagini', route: PathEnum.IMAGE_LIBRARY },
  { label: 'User Manager', route: PathEnum.USER_MANAGER },
];

interface SidebarItem {
  label: string;

  route: string;
}
