import { ImageDTO } from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import { imageLibraryGetLibrary } from './actions';

interface InitialValues {
  listResponse: ImageDTO[] | undefined;
}

const initialState: InitialValues = {
  listResponse: undefined,
};

export const imageLibraryReducer = createReducer(initialState, builder => {
  builder.addCase(imageLibraryGetLibrary.fulfilled, (state, action) => {
    state.listResponse = action.payload;
  });
});
