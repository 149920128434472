import { AttivitaDTO, ImageDTO } from '@api/client';
import { useAppDispatch } from 'core/store/hooks';
import { Formik } from 'formik';
import { useState } from 'react';
import AppButton from 'shared/design-system/components/app-button';
import AppDropzone from 'shared/design-system/components/app-dropzone';
import AppModal from 'shared/design-system/components/app-modal';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import { FileUtils } from 'shared/utility/files.utils';
import * as Yup from 'yup';
import { imageLibraryCreate, imageLibraryEdit } from '../_redux/actions';

interface Props {
  show: boolean;
  onHide: (reload?: boolean) => void;
  ent?: ImageDTO | undefined;
}

const ImageLibraryEditModal: React.FC<Props> = props => {
  const { show, ent, onHide } = props;

  const dispatch = useAppDispatch();

  const [data, setData] = useState<AttivitaDTO | undefined>();

  const handleHide = (reload?: boolean) => {
    setData(undefined);
    onHide(reload);
  };

  const handleSubmit = async (values: FormValues) => {
    let request;

    let image: string | undefined;

    if (values.image) {
      await FileUtils.toBase64(values.image)
        .then(res => {
          image = res;
        })
        .catch(e => {
          AppToastService.error("Errore nell'immagine!");
        });
    }

    if (!image) return;

    if (ent) {
      request = dispatch(imageLibraryEdit({ id: ent.id!, values: image }));
    } else request = dispatch(imageLibraryCreate(image));

    request.unwrap().then(res => {
      if (res) {
        AppToastService.success(
          `Immagine ${ent ? 'modificata' : 'aggiunta'} con successo`,
        );

        handleHide(true);
      }
    });
  };

  if (!show) return null;

  return (
    <AppModal
      show={show}
      onClose={() => handleHide()}
      title={`${ent ? 'Modifica' : 'Aggiungi'} Immagine`}
      block
    >
      <Formik<FormValues>
        initialValues={{
          image: ent?.data ? FileUtils.fromBase64(ent?.data) : undefined,
        }}
        validationSchema={Yup.object().shape({
          image: Yup.string().required(),
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleSubmit: _handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          const handleAddFile = (file: File) => {
            setFieldValue('image', file);
          };

          const handleDeleteFile = () => {
            setFieldValue('image', undefined);
          };

          return (
            <form
              onSubmit={_handleSubmit}
              className='grid grid-cols-1 gap-3'
              autoComplete='off'
            >
              <AppDropzone
                accept='image'
                label='Immagine'
                file={values?.image}
                onDrop={handleAddFile}
                onDelete={handleDeleteFile}
                isInvalid={!!errors?.image && touched?.image}
                error={errors?.image}
              />

              <div className='grid grid-cols-1 md:grid-cols-2 form-actions'>
                <div className='order-2 md:order-1'>
                  <AppButton
                    color='white'
                    onClick={() => handleHide()}
                    className='w-full md:w-auto'
                  >
                    Annulla
                  </AppButton>
                </div>

                <div className='text-end order-1 md:order-2'>
                  <AppButton type='submit' className='w-full md:w-auto'>
                    Salva
                  </AppButton>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </AppModal>
  );
};
export default ImageLibraryEditModal;

interface FormValues {
  image?: File;
}
