import { ImageDTO } from '@api/client';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { imageLibraryGetLibrary } from 'pages/private/image-library/_redux/actions';
import { imageLibraryListSelector } from 'pages/private/image-library/_redux/selectors';
import { useEffect } from 'react';
import AppModal from 'shared/design-system/components/app-modal';
import './style.scss';

interface Props {
  show: boolean;
  onHide: (image?: ImageDTO) => void;
}

const ImageLibraryChooseModal: React.FC<Props> = props => {
  const { show, onHide } = props;

  const dispatch = useAppDispatch();

  const library = useAppSelector(imageLibraryListSelector);

  const handleHide = (image?: ImageDTO) => {
    onHide(image);
  };

  const getData = () => {
    dispatch(imageLibraryGetLibrary());
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AppModal
      show={show}
      onClose={handleHide}
      title='Libreria Immagini'
      className='image-library-choose-modal'
    >
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 my-5'>
        {library?.map((item, i) => (
          <div
            key={`image_library-${i}`}
            className='card image-library-choose-modal__item'
            onClick={() => handleHide(item)}
          >
            <figure>
              <img src={item?.data ? `data:image/jpg;base64,${item?.data}` : ''} />
            </figure>
          </div>
        ))}
      </div>
    </AppModal>
  );
};
export default ImageLibraryChooseModal;
