import {
  ApiAttivitaEntIdDeleteRequest,
  ApiImageLibraryEntIdPutRequest,
  ApiImageLibraryPostRequest,
  ImageDTO,
  ImageLibraryApi,
} from '@api/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import getConfiguration, { errorHandler } from 'core/store/config';

enum ImageLibraryReducer {
  getLibrary = '[IMAGE LIBRARY] Get Library',
  create = '[IMAGE LIBRARY] Create',
  edit = '[IMAGE LIBRARY] Edit',
  delete = '[IMAGE LIBRARY] Delete',
}

export const imageLibraryGetLibrary = createAsyncThunk<
  ImageDTO[] | undefined,
  boolean | void,
  ThunkApiConfig
>(ImageLibraryReducer.getLibrary, async (forceReload, { getState }) => {
  try {
    let state = getState()?.private?.imageLibrary;

    if (state?.listResponse && !forceReload) {
      return state.listResponse;
    }

    return await new ImageLibraryApi(getConfiguration()).apiImageLibraryGet();
  } catch (e) {
    errorHandler(e);
  }
});

export const imageLibraryCreate = createAsyncThunk<
  string | undefined,
  string,
  ThunkApiConfig
>(ImageLibraryReducer.create, async (values, {}) => {
  try {
    let request: ApiImageLibraryPostRequest = {
      body: values,
    };

    return await new ImageLibraryApi(getConfiguration()).apiImageLibraryPost(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const imageLibraryEdit = createAsyncThunk<
  string | undefined,
  { id: number; values: string },
  ThunkApiConfig
>(ImageLibraryReducer.edit, async (req, {}) => {
  try {
    let request: ApiImageLibraryEntIdPutRequest = {
      entId: req.id,
      body: req.values,
    };

    return await new ImageLibraryApi(getConfiguration()).apiImageLibraryEntIdPut(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const imageLibraryDelete = createAsyncThunk<
  void | undefined,
  number,
  ThunkApiConfig
>(ImageLibraryReducer.delete, async (entId, {}) => {
  try {
    let request: ApiAttivitaEntIdDeleteRequest = {
      entId,
    };

    return await new ImageLibraryApi(getConfiguration()).apiImageLibraryEntIdDelete(
      request,
    );
  } catch (e) {
    errorHandler(e);
  }
});
