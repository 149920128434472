import { PathEnum } from 'core/routes/path.enum';
import { Navigate, Route, Routes } from 'react-router-dom';
import ActivitiesPage from './activities';
import ActivityDetailsPage from './activities/activity-details';
import ImageLibraryPage from './image-library';
import UserManagerPage from './user-manager';

const PrivateRouter: React.FC = () => {
  return (
    <Routes>
      <Route path='*' element={<Navigate to={PathEnum.ACTIVITIES} />} />

      <Route path={PathEnum.ACTIVITIES} element={<ActivitiesPage />} />
      <Route path={`${PathEnum.ACTIVITIES}/:entId`} element={<ActivityDetailsPage />} />

      <Route path={PathEnum.LIBRARY} element={<ActivitiesPage isLibrary />} />
      <Route
        path={`${PathEnum.LIBRARY}/:entId`}
        element={<ActivityDetailsPage isLibrary />}
      />

      <Route path={`${PathEnum.IMAGE_LIBRARY}`} element={<ImageLibraryPage />} />

      <Route path={PathEnum.USER_MANAGER} element={<UserManagerPage />} />
    </Routes>
  );
};
export default PrivateRouter;
