/* tslint:disable */
/* eslint-disable */
/**
 * David Master Api
 * db: david-master-svil
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ImageDTOFromJSON, } from '../models';
/**
 *
 */
export class ImageLibraryApi extends runtime.BaseAPI {
    /**
     */
    apiImageLibraryEntIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.entId === null || requestParameters.entId === undefined) {
                throw new runtime.RequiredError('entId', 'Required parameter requestParameters.entId was null or undefined when calling apiImageLibraryEntIdDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/ImageLibrary/{entId}`.replace(`{${"entId"}}`, encodeURIComponent(String(requestParameters.entId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    apiImageLibraryEntIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.apiImageLibraryEntIdDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    apiImageLibraryEntIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.entId === null || requestParameters.entId === undefined) {
                throw new runtime.RequiredError('entId', 'Required parameter requestParameters.entId was null or undefined when calling apiImageLibraryEntIdGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/ImageLibrary/{entId}`.replace(`{${"entId"}}`, encodeURIComponent(String(requestParameters.entId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    apiImageLibraryEntIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiImageLibraryEntIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiImageLibraryEntIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.entId === null || requestParameters.entId === undefined) {
                throw new runtime.RequiredError('entId', 'Required parameter requestParameters.entId was null or undefined when calling apiImageLibraryEntIdPut.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/ImageLibrary/{entId}`.replace(`{${"entId"}}`, encodeURIComponent(String(requestParameters.entId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.body,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    apiImageLibraryEntIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiImageLibraryEntIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiImageLibraryGetRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/ImageLibrary`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImageDTOFromJSON));
        });
    }
    /**
     */
    apiImageLibraryGet(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiImageLibraryGetRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiImageLibraryPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/ImageLibrary`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.body,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    apiImageLibraryPost(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiImageLibraryPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
