import { combineReducers } from '@reduxjs/toolkit';
import { activitiesReducer } from '../activities/_redux/reducer';
import { imageLibraryReducer } from '../image-library/_redux/reducer';
import { userManagerReducer } from '../user-manager/_redux/reducer';

export const privateReducer = combineReducers({
  userManager: userManagerReducer,
  activities: activitiesReducer,
  imageLibrary: imageLibraryReducer,
});
