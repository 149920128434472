import { AttivitaDTO } from '@api/client';
import { authUserSelector } from 'core/auth/_redux/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { activitiesDelete } from 'pages/private/activities/_redux/actions';
import ActivityEditModal from 'pages/private/activities/activity-edit-modal';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppButton from 'shared/design-system/components/app-button';
import AppConfirm from 'shared/design-system/components/app-confirm';
import AppMenuDropdown from 'shared/design-system/components/app-menu-dropdown';
import AppSpanValue from 'shared/design-system/components/app-span-values';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import useTextToSpeech from 'shared/hooks/useTts';
import './style.scss';

interface Props {
  activity: AttivitaDTO;
  onEdit: () => void;
  onDelete: () => void;

  isLibrary?: boolean;
}

const ActivityCard: React.FC<Props> = props => {
  const { activity, isLibrary, onDelete, onEdit } = props;
  const { entId } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const tts = useTextToSpeech();

  const user = useAppSelector(authUserSelector);

  const [itemDelete, setItemDelete] = useState<number>();
  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  const goToDetails = () => {
    if (!activity?.id) return console.error('no id found');

    let routeState = location?.state?.activitiesDetails || [];
    routeState?.push(activity);

    navigate(`${entId ? '.' : ''}./${activity?.id}`, {
      relative: 'path',
      state: {
        activitiesDetails: routeState,
      },
    });
  };

  const handleEdit = () => {
    if (activity?.id) setShowEditForm(true);
  };

  const handleDelete = () => {
    setItemDelete(activity?.id);
  };

  const handleConfirmDelete = () => {
    if (!itemDelete) return console.error('itemDelete not found');

    dispatch(activitiesDelete(itemDelete)).then(res => {
      if (res) {
        setItemDelete(undefined);
        AppToastService.success('Attività eliminata con successo');
        onDelete();
      }
    });
  };

  return (
    <>
      <div className='card activity-card'>
        <figure>
          <img
            src={
              activity?.image?.data ? `data:image/jpg;base64,${activity?.image.data}` : ''
            }
          />
        </figure>

        <div className='card-body px-3 py-5'>
          <div className='flex flex-row items-center'>
            <h6 className='text-bold flex-1'>{activity?.name}</h6>

            <AppButton
              onClick={() => {
                tts.play(activity?.name || '');
              }}
              color='white'
              size='xs'
              className='flex-0'
              title='Riproduci'
            >
              <i className='fas fa-volume-high' />
            </AppButton>
          </div>

          {user?.isAdmin && !isLibrary && (
            <>
              <hr />
              <AppSpanValue title='Utente' value={activity?.user?.email} size='sm' />
            </>
          )}
        </div>

        <div className='card-actions flex p-3'>
          <AppButton
            size='sm'
            color='secondary'
            variant='outline'
            onClick={() => goToDetails()}
            className='flex-1'
          >
            Dettaglio
          </AppButton>

          <AppMenuDropdown
            options={[
              {
                label: 'Modifica',
                icon: <i className='fas fa-pencil' />,
                onClick: () => handleEdit(),
              },
              {
                label: 'Cancella',
                icon: <i className='fas fa-trash' />,
                onClick: () => handleDelete(),
              },
            ]}
          />
        </div>
      </div>

      <AppConfirm
        show={!!itemDelete}
        onConfirm={() => handleConfirmDelete()}
        onHide={() => setItemDelete(undefined)}
        title='Elimina attività'
        message={
          <>
            <div className='mb-4'>Confermi di voler eleminilare l'attività?</div>

            <div>
              <b>
                NB: Confermando eliminerai anche tutte le sottoattività corrispondendi
              </b>
            </div>
          </>
        }
      />

      <ActivityEditModal
        show={showEditForm}
        entId={activity?.id}
        onHide={e => {
          if (e) onEdit();
          setShowEditForm(false);
        }}
        isLibrary={isLibrary}
      />
    </>
  );
};
export default ActivityCard;
