import { ImageDTO } from '@api/client';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { useEffect, useState } from 'react';
import AppButton from 'shared/design-system/components/app-button';
import AppConfirm from 'shared/design-system/components/app-confirm';
import AppNoResults from 'shared/design-system/components/app-no-results';
import AppPage from 'shared/design-system/components/app-page';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import { imageLibraryDelete, imageLibraryGetLibrary } from './_redux/actions';
import { imageLibraryListSelector } from './_redux/selectors';
import ImageLibraryEditModal from './image-library-edit-modal';

const ImageLibraryPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const response = useAppSelector(imageLibraryListSelector);

  const [showEditForm, setShowEditForm] = useState<{ show: boolean; ent?: ImageDTO }>({
    show: false,
  });
  const [showDelete, setShowDelete] = useState<ImageDTO>();

  const handleNewClick = (ent?: ImageDTO) => {
    setShowEditForm({ show: true, ent });
  };

  const handleNew = (reload?: boolean) => {
    if (reload) getData();

    setShowEditForm({ show: false });
  };

  const handleDeleteClick = (ent: ImageDTO) => {
    setShowDelete(ent);
  };

  const handleDelete = () => {
    dispatch(imageLibraryDelete(showDelete?.id!))
      .unwrap()
      .then(() => {
        AppToastService.success('Immagine cancellata con successo!');
        setShowDelete(undefined);
        getData();
      });
  };

  const getData = () => {
    dispatch(imageLibraryGetLibrary(true));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AppPage
        title='Libreria Immagini'
        actions={
          <AppButton onClick={() => handleNewClick()}>
            <i className='fas fa-plus me-2' />
            Aggiungi
          </AppButton>
        }
      >
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 my-5'>
          {response?.map((item, i) => (
            <div className='card activity-card'>
              <figure>
                <img src={item?.data ? `data:image/jpg;base64,${item?.data}` : ''} />
              </figure>

              <div className='card-actions flex p-3'>
                <AppButton
                  size='sm'
                  color='secondary'
                  variant='outline'
                  className='flex-1'
                  onClick={() => handleNewClick(item)}
                >
                  Modifica
                </AppButton>

                <AppButton
                  size='sm'
                  color='danger'
                  title='Elimina'
                  onClick={() => handleDeleteClick(item)}
                >
                  <i className='fas fa-trash' />
                </AppButton>
              </div>
            </div>
          ))}
        </div>

        {response?.length == 0 && <AppNoResults />}
      </AppPage>

      <ImageLibraryEditModal
        show={showEditForm?.show}
        onHide={handleNew}
        ent={showEditForm.ent}
      />

      <AppConfirm
        show={!!showDelete}
        title='Cancella immagine'
        message="Confermi di voler cancellare l'immagine?"
        onConfirm={handleDelete}
      />
    </>
  );
};
export default ImageLibraryPage;
